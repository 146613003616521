import { FC } from 'react'
import { CSSTransition } from 'react-transition-group'
import styles from './animateCard.module.scss'

type IProps = {
  value: string[] //  ["0", "0"]
}
const AnimateCard: FC<IProps> = (props) => {
  return (
    <>
      {props.value.map((item, index) => (
        <div className={styles['card-group']} key={index}>
          <div className={styles['roll-container']}>
            <CSSTransition timeout={200} classNames={styles['roll']}>
              <div className={styles['roll-number']} key="item">
                {item}
              </div>
            </CSSTransition>
          </div>
        </div>
      ))}
    </>
  )
}

export default AnimateCard
