import { useState, useEffect, useMemo, memo } from 'react'
import { cn } from '@/lib/utils'
import { useTranslation } from '@/lib/locales/client'
import { useAppStore } from '@/stores/app'
import { useUserStore } from '@/stores/user'
import { useAuthStore } from '@/stores/authControl'
import { useRouter } from 'next/navigation'
import { getJackpotListApi } from '@/api/home'
// import AnimationNum from "@/components/base/AnimationNum.vue"
import { openGame } from '@/components/game/startGame'
import { numThFilter } from '@/lib/filter'
import styles from '../_common_css/homeIslot.module.scss'
import { useShallow } from 'zustand/react/shallow'

const HomeIslot = () => {
  const { t } = useTranslation()
  const { isBigScreen, language } = useAppStore(
    useShallow((state) => ({
      isBigScreen: state.isBigScreen,
      language: state.language
    }))
  )
  const openLogin = useAuthStore((state) => state.openLogin)
  const { isLogin, userInfo } = useUserStore(
    useShallow((state) => ({
      isLogin: state.isLogin,
      userInfo: state.userInfo
    }))
  )
  const router = useRouter()
  const [jackpotList, setJackpotList] = useState([])
  const [gameLabelList, setGameLabelList] = useState([])

  const isTry = isLogin ? 0 : 1
  const go2Lobby = () => {
    if (!isLogin) {
      openLogin()
      router.push('/login')
      return
    }

    const params = {
      gameType: 'real',
      gameCode: 'A',
      childLobbyCode: 'ASISLOT',
      lobbyCode: 'ASISLOT',
      loginName: userInfo.loginName
    }

    openGame(isTry, params)
  }

  const getTypeValue = (gameName) => {
    return gameLabelList.find((item) => item.gameName === gameName)?.value
  }

  const amountValue = useMemo(() => {
    let num = 0
    if (jackpotList.length === 0) {
      num = 0
    } else {
      const newJackPot = [...jackpotList]
      newJackPot.forEach((item) => {
        num += Number(item.jackpot)
      })
      setJackpotList(newJackPot)
    }

    return num
  }, [])

  const getNumImage = (num) => {
    const numMap = {
      0: '/static/images/theme-red/nb/0.png',
      1: '/static/images/theme-red/nb/1.png',
      2: '/static/images/theme-red/nb/2.png',
      3: '/static/images/theme-red/nb/3.png',
      4: '/static/images/theme-red/nb/4.png',
      5: '/static/images/theme-red/nb/5.png',
      6: '/static/images/theme-red/nb/6.png',
      7: '/static/images/theme-red/nb/7.png',
      8: '/static/images/theme-red/nb/8.png',
      9: '/static/images/theme-red/nb/9.png',
      ',': '/static/images/theme-red/nb/poin.png',
      '.': '/static/images/theme-red/nb/point.png',
      $: '/static/images/theme-red/nb/money.png'
    }

    return numMap[num]
  }

  const getJackpotList = () => {
    getJackpotListApi().then((res) => {
      const list = []
      res.data?.jackpotList?.forEach((item, index) => {
        const imgSrc = item.jackpotName.replace(/\s*/g, '').toLowerCase()
        list.push({
          ...item,
          imgSrc,
          gameIndex: index,
          typeValue: getTypeValue(imgSrc)
        })
      })

      setJackpotList(list)
    })
  }

  const getGameLabelListData = () => {
    const arr = {
      DFDC: 'duofuduocai',
      JJBX: 'jinjibaoxi',
      Mystery: '5dragonsgold'
    }
    const list = []
    // --- 待处理  搜不到此API
    // _this.$api.getgameLabelList({ gameType: "real" }).then((res) => {
    //   res.data.forEach((item) => {
    //     list.push({
    //       ...item,
    //       gameName: arr?.[item.label] || item?.labelNoSpaces || item.label.replace(/\s*/g, "").toLowerCase()
    //     })
    //   })
    //   setGameLabelList(list)

    //   getJackpotList()
    // })
  }
  let timer = null

  useEffect(() => {
    getGameLabelListData()
    timer = setInterval(() => {
      getGameLabelListData()
    }, 1000 * 30)

    return () => {
      clearInterval(timer)
    }
  }, [])

  return (
    <>
      <div
        className={cn(
          styles['home-islot'],
          styles[isBigScreen ? 'pc' : 'h5'],
          styles[language === 'en-US' && 'en']
        )}
        onClick={go2Lobby}
      >
        {isBigScreen && (
          <div className={'islot-btn'} onClick={go2Lobby}>
            {t('common.game.real')}
            <img className={'arrow'} src="/static/images/theme-red/arrow-right-white.svg" alt="" />
          </div>
        )}

        <div className={'jack-pot-warp'}>
          {/* --- 待处理 */}
          {/* <AnimationNum num={amountValue}>
        <template v-slot="slotProps">
          <div className={styles["num_warp"]}>
            <img
              value={"$"}
              src={getNumImage(`$`)}
            />
            {numThFilter(slotProps.value, 2).map((num, index) => (
            <img
            key={index}
            value={num}
            src={getNumImage(`${num}`)}
          />

            ))}

          </div>
        </template>
      </AnimationNum> */}
        </div>
      </div>
    </>
  )
}

export default memo(HomeIslot)
