import { FC, memo } from 'react'
import { cn } from '@/lib/utils'
import { useAppStore } from '@/stores/app'
import styles from './index.module.scss'
import { webpHandle } from '@/hooks/aboutWebp'
import { Autoplay, Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { jumpURL } from '@/lib/jumpURL'
import { onHandleAlt } from '@/lib/utils'

type IProps = {
  homeBanner?: any[]
  isPromo?: boolean
}

const BannerContainer: FC<IProps> = (props) => {
  const isBigScreen = useAppStore((state) => state.isBigScreen)
  return (
    <>
      <div className={cn(styles['home_banner_red_box_new'], styles[isBigScreen ? 'pc' : 'h5'])}>
        <Swiper
          className={cn(props.isPromo ? 'hei-ght' : '', 'home-swiper-box')}
          modules={[Autoplay, Navigation, Pagination]}
          grabCursor
          centeredSlides
          slidesPerView={1}
          effect={isBigScreen ? undefined : 'coverflow'}
          coverflowEffect={
            isBigScreen
              ? undefined
              : {
                  rotate: 0,
                  // stretch: {'25.6%'}, //  待处理
                  depth: 30,
                  modifier: 2,
                  slideShadows: false
                }
          }
          spaceBetween={8}
          loop={props.homeBanner.length > 1}
          autoplay={
            props.homeBanner.length > 1
              ? {
                  delay: 2500,
                  disableOnInteraction: false,
                  pauseOnMouseEnter: true
                }
              : false
          }
          pagination={{
            el: '.my-pagination'
          }}
        >
          {props.homeBanner.map((item, index) => (
            <SwiperSlide onClick={() => jumpURL(item.templateAction)} key={index}>
              {item.mediaLibrary[0] && (
                <img
                  className={'banner-icon'}
                  src={webpHandle(item.mediaLibrary[0].fullWebpFilePath, item.mediaLibrary[0].fullFilePath)}
                  alt={onHandleAlt(item)}
                />
              )}
            </SwiperSlide>
          ))}
          <div className={'my-pagination'}></div>
        </Swiper>
      </div>
    </>
  )
}

export default memo(BannerContainer)
