'use client'
import { useState, useEffect, useMemo, FC, memo } from 'react'
import { cn } from '@/lib/utils'
import { useTranslation } from '@/lib/locales/client'
import { Lng } from '@/lib/locales/i18n'
import { useAppStore } from '@/stores/app'
import { useUserStore } from '@/stores/user'
import { useGameStore } from '@/stores/game'
import { useRouter, usePathname } from 'next/navigation'
import { CSSTransition } from 'react-transition-group'
import { Tabs } from 'antd'
import dayjs from 'dayjs'
import { webpHandle } from '@/hooks/aboutWebp'
import Config from '@/config/base'
import dynamic from 'next/dynamic'
import styles from './index.module.scss'
import { useShallow } from 'zustand/react/shallow'
/** api */
import { getAmigoInfo } from '@/api/game'
import { transferOutAll } from '@/api/activity'
import { cmsTopList2, cmsGameLabels } from '@/api/home'
/* com */
import Banner from './_components/banner'
import GamesLobby from './_components/common/gamesLobby'
import HomeTopGames from './_components/common/misc/homeTopGames'
import GameProvideSearch from '@/components/game/gameProvideSearch'
import HomeGameListMobile from './_components/common/homeGameListMobile'
import HomeIcons from './_components/common/misc/homeIcons'
import HomeIslot from './_components/common/misc/homeIslot'
// --- 待处理
import HomePrizeGuide from './_components/common/misc/homePrizeGuideMobile'
// import FastDeposit from '@/views/fastDeposit/index.vue'
// import JackPotOld from './old/jackPot.vue'
// import AutoMusic from './autoMusic.vue'
// import FooterBigWin from "./footerBigWin.vue"

// 原项目的HomeRed/index

const HomePage: FC<{ lng: Lng; device: string }> = ({ lng, device }) => {
  const { t } = useTranslation()
  // const urlParams = useParams()
  // <{ tag: string; item: string }>
  // console.log('首页调用', lng, device)

  const { isBigScreen, isShowFastDeposit, globalPublickKey, isNewVer, changeFastDialog, updateCmsTemplate } =
    useAppStore(
      useShallow((state) => ({
        isBigScreen: state.isBigScreen,
        isShowFastDeposit: state.isShowFastDeposit,
        globalPublickKey: state.globalPublickKey,
        isNewVer: state.isNewVer,
        changeFastDialog: state.changeFastDialog,
        updateCmsTemplate: state.updateCmsTemplate
      }))
    )
  const { SET_HIDDEN_BIG_WIN, SET_HOME_CUR_TAB } = useGameStore(
    useShallow((state) => ({
      SET_HIDDEN_BIG_WIN: state.SET_HIDDEN_BIG_WIN,
      SET_HOME_CUR_TAB: state.SET_HOME_CUR_TAB
    }))
  )
  const { isLogin, depositActivityInfo, userInfo } = useUserStore(
    useShallow((state) => ({
      isLogin: state.isLogin,
      depositActivityInfo: state.depositActivityInfo,
      userInfo: state.userInfo
    }))
  )
  // console.log('globalPublickKey', globalPublickKey)
  const router = useRouter()
  const pathName = usePathname()
  const [homeBanner, setHomeBanner] = useState([])
  const [homeLobby, setHomeLobby] = useState([])
  const [frontDataList, setFrontDataList] = useState([])
  const [topGameList, setTopGameList] = useState([])
  const [isShowHomeISlot, setIsShowHomeISlot] = useState(false)
  const [icons, setIcons] = useState([])
  const [gameVideos, setGameVideos] = useState([])
  const [prizeGuideBigBg, setPrizeGuideBigBg] = useState('')
  const [prizeGuideSmallBg, setPrizeGuideSmallBg] = useState('')
  const [homeTestimonials, setHomeTestimonials] = useState([])

  // 首页标签页内模块
  const [activeTab, setActiveTab] = useState<any>(0)
  const [countDown, setCountDown] = useState(3)

  // let timer
  // const clearTimer = () => {
  //   setCountDown(3)
  //   clearInterval(timer)
  //   timer = null
  // }

  // ---  待处理
  const componentsData: any = useMemo(
    () => [
      // gameVideos, frontDataList, homeLobby, topGameList, homeTestimonials
      {
        label: t('common.account.top'),
        // Component: () => import('./home/modules/top')
        Component: dynamic(() => import('./_components/top'), { ssr: false })
      },
      {
        label: t('common.account.kuiPachi'),
        Component: dynamic(() => import('./_components/kuiPachi'), { ssr: false })
      },
      {
        label: t('common.account.slots'),
        Component: dynamic(() => import('./_components/slots'), { ssr: false })
      },
      {
        label: t('common.account.liveCasino'),
        Component: dynamic(() => import('./_components/liveCasino'), { ssr: false })
      },
      {
        label: t('common.account.bountyFisher'),
        Component: dynamic(() => import('./_components/bountyFisher'), { ssr: false })
      }
    ],
    []
  )

  const dataFmt = (v) => {
    return dayjs(v).format('YYYY-MM-DD')
  }

  function onGetCMSData() {
    let params = {
      moduleCodeList: [
        'homeBanner',
        'homeLobby',
        'homePromotion',
        'homeSpokesman',
        'homeISlot',
        'floatbutton',
        'homeGameVideos',
        'limitedTimeBgNew',
        'homeTestimonials'
      ],
      themeCode: Config.themeCode
    }
    return updateCmsTemplate(params).then((data) => {
      setHomeBanner(data.filter((item) => item.moduleCode === 'homeBanner'))
      setHomeLobby(data.filter((item) => item.moduleCode === 'homeLobby'))
      setIcons(data.filter((item) => item.moduleCode === 'floatbutton'))
      setIsShowHomeISlot(data.filter((item) => item.moduleCode === 'homeISlot')?.length > 0)
      setGameVideos(data.filter((item) => item.moduleCode === 'homeGameVideos'))
      setHomeTestimonials(data.filter((item) => item.moduleCode === 'homeTestimonials'))
      const bgArr = data.filter((item) => item.moduleCode === 'limitedTimeBgNew')[0] || []
      bgArr.mediaLibrary?.forEach((el) => {
        if (el.resourceType === 1) {
          setPrizeGuideBigBg(webpHandle(el.fullWebpFilePath, el.fullFilePath))
        } else if (el.resourceType === 2) {
          setPrizeGuideSmallBg(webpHandle(el.fullWebpFilePath, el.fullFilePath))
        }
      })
    })
  }

  // 首页精选 --- 待处理
  function getTopList() {
    cmsTopList2({ moduleCode: 'topList', username: userInfo.loginName }).then((res: any) => {
      // console.log('读取 顶部', res)
      if (res.code === 200 && res.data.length > 0) {
        let gameIdList = []
        res.data.forEach((el) => {
          el.detailsList.forEach((i) => {
            gameIdList.push(i.gameId)
          })
        })
        if (gameIdList.length > 0) {
          getAmigoInfo({ gameIdList }).then((info: any) => {
            // console.log('读取 阿民高的数据', info)
            if (info.success && info.data) {
              res.data.forEach((el) => {
                el.detailsList.forEach((i) => {
                  i.providerCode = i.lobbyCode
                  i.platformCode = i.lobbyCode
                  const gameProperty = info.data[i.gameId]
                  if (gameProperty) {
                    i.gameProperties = { gameProperty }
                  }
                })
              })

              const amigoData = res.data.map((item) => ({
                ...item,
                detailsList: item.detailsList.filter((i) => i.gameProperties?.gameProperty?.state === 1)
              }))
              // console.log('集中阿民高', amigoData)
              setTopGameList(amigoData)
            }
          })
        }
      }
    })
    getGameLabels()
  }

  function getGameLabels() {
    return cmsGameLabels({ moduleCode: 'labelClassification', username: userInfo.loginName }).then(
      (res: any) => {
        if (res.code === 200 && res.success && res.data.length > 0) {
          res.data.forEach((el) => {
            el.detailsList.forEach((ll) => {
              /**
               * 因ll.providerCode = ll.lobbyCode中providerCode与以前进游戏有关，不可删除；
               * providerName: 搜索页数据来源/element/material/game_page，无providerCode字段；
               * providerName: 首页Casino模块中数据来源/cms/open/gameLabels/gameLabelsList，需使用providerCode字段；
               * 进游戏仅需使用gameId与providerCode，不受字段影响，故赋值在providerName中进行使用。
               */
              ll.providerName = ll.providerCode
              ll.providerCode = ll.lobbyCode
              ll.platformCode = ll.lobbyCode
            })
          })
          setFrontDataList(res.data || [])
        }
      }
    )
  }

  const init = () => {
    Promise.all([onGetCMSData(), getTopList()])
    if (isLogin) {
      transferOutAll({ loginName: userInfo?.loginName })
    }
  }

  // 待观察 最先调用request-old的接口的地方
  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    if (isLogin) init()
  }, [isLogin])

  useEffect(() => {
    if (globalPublickKey) init()
  }, [globalPublickKey])

  const activeTabType = (val) => {
    const type = {
      1: ['pachinko', 'pachislot'],
      2: 'slot',
      3: 'fish',
      4: 'live',
      5: 'sprot'
    }
    if (val) return type[val]
  }

  // useEffect(() => {
  //   console.log('one love', activeTab)
  //   clearTimer()
  //   SET_HIDDEN_BIG_WIN(false)
  //   SET_HOME_CUR_TAB(activeTab === 0 ? 'all' : activeTabType(activeTab))
  //   if (activeTab === 1 || activeTab === 2 || activeTab >= 6) return
  // }, [activeTab])

  // 待处理 onActivated
  // useEffect(() => {
  // if (globalPublickKey) init()
  // 用keepalive 在组件插入时调用
  // onActivated(() => {
  //   init()
  // })

  //   return () => {
  //     clearInterval(timer)
  //     timer = null
  //   }
  // }, [])

  const onChange = (activeTab) => {
    // clearTimer()
    SET_HIDDEN_BIG_WIN(false)
    SET_HOME_CUR_TAB(activeTab === 0 ? 'all' : activeTabType(activeTab))
    if (activeTab === 1 || activeTab === 2 || activeTab >= 6) {
      return
    }
  }

  return (
    <>
      <div
        key={isBigScreen ? 'pc-home' : 'h5-home'}
        className={cn(styles['home-red-page'], styles[isBigScreen ? 'pc' : 'h5'])}
      >
        {/* 首页轮播图 */}
        <div className={cn(isLogin && 'login')}>
          {!isBigScreen ? (
            <div>
              {/* 不定时隐藏该优惠图 */}
              {depositActivityInfo?.extraResult && depositActivityInfo.extraResult.depositTime < 3 && (
                <CSSTransition
                  timeout={220}
                  in={prizeGuideSmallBg || prizeGuideBigBg}
                  unmountOnExit
                  classNames={'fade'}
                >
                  <div onClick={() => changeFastDialog(true)} className={'home-top-promo'}>
                    <p className={'welcom-txt'}>
                      Hi {userInfo.loginName}! {t('user.regSuc.newWelcome')}
                    </p>
                    <img style={{ width: '100%' }} src={prizeGuideSmallBg || prizeGuideBigBg} alt="" />
                    <div className={'rewards-box'}>
                      <strong>
                        +{depositActivityInfo.extraResult.bonusRate || 0}
                        <i>%</i>
                      </strong>
                      <div>
                        <p>REWARDS</p>
                        <span>End {dataFmt(depositActivityInfo.activityEndDate)}</span>
                      </div>
                      <img className={'turn-right'} src="/static/images/home/arrow-right.png" alt="right" />
                    </div>
                  </div>
                </CSSTransition>
              )}
              {Boolean(homeBanner.length) && <Banner homeBanner={homeBanner} />}
              {/* 首页标签页 */}
              <div className={'home-tabs-box-warp'}>
                {!isBigScreen && (
                  <Tabs
                    className={'home-tabs-box'}
                    defaultActiveKey="0"
                    items={componentsData.map((item, index) => {
                      return {
                        key: index,
                        label: item.label,
                        children: (
                          <item.Component
                            data={{
                              gameVideos,
                              frontDataList,
                              homeLobby,
                              topGameList,
                              homeTestimonials
                            }}
                          />
                        )
                      }
                    })}
                    onChange={onChange}
                  />
                )}
              </div>
            </div>
          ) : (
            // 待观察
            <div>
              <HomePrizeGuide
                banner={homeBanner}
                prizeGuideBigBg={prizeGuideBigBg}
                prizeGuideSmallBg={prizeGuideSmallBg}
              />
              {/* 首页游戏商搜索栏暂时隐藏 */}
              <div className={'game-provide-search-wrap'}>
                {/* 待处理 */}
                {/* <GameProvideSearch className={'game-provide-search'} /> */}
              </div>
            </div>
          )}
        </div>
        {isBigScreen && (
          <div className={cn('top-wrap', 'mx-auto mt-[16px] flex max-w-[1000] flex-row justify-between')}>
            {/* 待处理 */}
            {/* <GamesLobby className={cn(isBigScreen && 'mt-[24px]', 'games-lobby')} list={homeLobby} /> */}

            {/* {gameVideos && gameVideos.length > 0 && (
              <HomeTopGames gameVideos={gameVideos} className={'home-top-games'} />
            )} */}
          </div>
        )}
        <div className={cn('home-bottom-list', 'mx-auto max-w-[1000]')}>
          {(!isNewVer || isBigScreen) && (
            <>
              {/* <JackPotOld className="[isBigScreen ? 'gap-top-24' : 'gap-top-14'" /> */}
              {/* {Boolean(topGameList.length) &&
                topGameList.map((game) => <HomeGameListMobile key={game.topListId} gameListData={game} />)} */}
            </>
          )}

          {isShowHomeISlot && <HomeIslot />}
        </div>
        {/* <AutoMusic /> */}
        <HomeIcons icons={icons} />
        {/* 待处理 */}
        {/* {isShowFastDeposit && pathName === '/' && <FastDeposit />} */}
      </div>
    </>
  )
}

export default memo(HomePage)
