import { useState, useEffect, FC, memo } from 'react'
import { useSetState } from 'ahooks'
import { cn } from '@/lib/utils'
import { useTranslation } from '@/lib/locales/client'
import { useAppStore } from '@/stores/app'
import { useUserStore } from '@/stores/user'
import { useAuthStore } from '@/stores/authControl'
import { useRouter, usePathname } from 'next/navigation'
import { getDesignationTasks, getAdditionalConfig, getCustomerDate } from '@/api/activity'
import Banner from '../../banner'
import CountDown from '@/components/countDown/index'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
// import HomeDeposit from '@/views/fastDeposit/homeDeposit.vue'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { format } from 'date-fns'
dayjs.extend(utc)
import styles from '../_common_css/homePrizeGuideMobile.module.scss'
import { useShallow } from 'zustand/react/shallow'

type IProps = {
  banner: any[]
  prizeGuideBigBg: string
  prizeGuideSmallBg: string
}

const HomePrizeGuideMobile: FC<IProps> = (props) => {
  const { t } = useTranslation()
  const { isBigScreen, language } = useAppStore(
    useShallow((state) => ({
      isBigScreen: state.isBigScreen,
      language: state.language
    }))
  )
  const { openLogin, openRegister } = useAuthStore(
    useShallow((state) => ({
      openLogin: state.openLogin,
      openRegister: state.openRegister
    }))
  )
  const { isLogin, depositActivityInfo, userInfo } = useUserStore(
    useShallow((state) => ({
      isLogin: state.isLogin,
      depositActivityInfo: state.depositActivityInfo,
      userInfo: state.userInfo
    }))
  )
  const router = useRouter()
  const pathName = usePathname()
  const [domFlag, setDomFlag] = useState(false)
  const [showFlag, setShowFlag] = useState(true)
  const top = 0
  const prizeMap = {
    0: 'REGISTER',
    1: 'FIRST_DEPOSIT',
    2: 'SECOND_DEPOSIT',
    3: 'THIRD_DEPOSIT',
    4: 'PENNY',
    5: 'PENNY'
  }
  const [prizeType, setPrizeType] = useState(prizeMap[0])

  // useEffect(() => {
  //   setPrizeType(prizeMap[0])
  // }, [])

  const [designationTasks, setDesignationTasks] = useState([])
  const [additionalTask, setAditionalTask] = useState('')
  const [endTime, setEndTime] = useState('')
  const [bonusArr, setBonusArr] = useState([])
  const [freeSpinArr, setFreeSpinArr] = useState([])

  let domHeight = 0
  let isFinish = false

  const startTimeMap = {
    register_date: 'registerDate',
    first_deposit_date: 'firstDepositDate',
    second_deposit_date: 'secondDepositDate',
    third_deposit_date: 'thirdDepositDate',
    complete_material_date: 'completeMaterialDate',
    contact_information_date: 'contactInformationDate',
    kyc_date: 'kycDate'
  }

  useEffect(() => {
    if (!depositActivityInfo) return
    let tar = depositActivityInfo
    // console.log('获取 tar?', tar)
    setDomFlag(true)
    if (isLogin) {
      setPrizeType(prizeMap[Number(tar.extraResult.depositTime) + 1])
      // prizeType.value === "FIRST_DEPOSIT" && queryDesignationTasks()
      if (tar.extraResult.bonusRate && tar.extraResult.bonusRate !== '0') {
        // 待处理
        // setBonusArr(String(parseInt(Number(tar.extraResult.bonusRate))).split(''))
        setBonusArr(String(parseInt(tar.extraResult.bonusRate)).split(''))
      } else {
        setBonusArr([])
      }
      setFreeSpinArr(tar.extraResult.freeSpinTimes > 0 ? String(tar.extraResult.freeSpinTimes).split('') : [])

      //设置倒计时
      if (tar.extraResult.countDownTime) {
        let nowTime = new Date(tar.sysNowTime).getTime() //当前时间 时间戳
        let coutDownTime = new Date(tar.extraResult.countDownTime).getTime() //倒计时时间 时间戳
        if (coutDownTime > nowTime) {
          setEndTime(format(new Date(tar.extraResult.countDownTime), 'yyyy-MM-dd HH:mm:ss'))
        } else {
          setEndTime('')
        }
      } else {
        setEndTime('')
      }
      //倒计时提示
      setAditionalTask(
        `${t('home.deposit.limit')}<span> ${tar.extraResult.freeSpinTimes} </span>${t('home.deposit.fs')}`
      )

      console.log(endTime)
    } else {
      // console.log('打印 undefined?', tar.extraResult.prizeAmounts)
      setBonusArr(String(tar.extraResult.prizeAmounts).split(''))
      setFreeSpinArr(
        tar.extraResult.freeSpinCounts > 0 ? String(tar.extraResult.freeSpinCounts).split('') : []
      )
    }
  }, [])
  // --- 待处理 原项目 依赖[depositActivityInfo]

  function goRegister() {
    if (prizeType === 'REGISTER') {
      openRegister(true)
      router.push('/register')
    } else {
      openLogin()
      router.push('/login')
    }
  }

  function formatTask(item) {
    let str = ''
    const task = {
      1: (amount, amountType, freeSpin) => {
        if (amountType === 'FIXED') {
          str = freeSpin
            ? `${t('home.deposit.tips5', { amount: amount })} + ${freeSpin}${t('home.deposit.fs')}`
            : `${t('home.deposit.tips5', { amount: amount })}`
        } else {
          str = freeSpin
            ? `${t('home.deposit.tips8', { amount: amount * 100 + '%' })} + ${freeSpin}${t(
                'home.deposit.fs'
              )}`
            : `${t('home.deposit.tips8', { amount: amount * 100 + '%' })}`
        }
      },
      2: (amount, amountType, freeSpin) => {
        if (amountType === 'FIXED') {
          str = freeSpin
            ? `${t('home.deposit.tips6', { amount: amount })} + ${freeSpin}${t('home.deposit.fs')}`
            : `${t('home.deposit.tips6', { amount: amount })}`
        } else {
          str = freeSpin
            ? `${t('home.deposit.tips9', { amount: amount * 100 + '%' })} + ${freeSpin}${t(
                'home.deposit.fs'
              )}`
            : `${t('home.deposit.tips9', { amount: amount * 100 + '%' })}`
        }
      },
      3: (amount, amountType, freeSpin) => {
        if (amountType === 'FIXED') {
          str = freeSpin
            ? `${t('home.deposit.tips7', { amount: amount })} + ${freeSpin}${t('home.deposit.fs')}`
            : `${t('home.deposit.tips7', { amount: amount })}`
        } else {
          str = freeSpin
            ? `${t('home.deposit.tips0', { amount: amount * 100 + '%' })} + ${freeSpin}${t(
                'home.deposit.fs'
              )}`
            : `${t('home.deposit.tips0', { amount: amount * 100 + '%' })}`
        }
      }
    }
    task[item.type](item.rewardAmount, item.rewardAmountType, item.freeSpins)
    return str
  }

  function handleClick() {
    if (isFinish) {
      setShowFlag(true)
    }
  }
  // useEffect(() => {
  //   if(isLogin) setStep(1)
  // }, [])

  return (
    <>
      <div
        className={cn(
          styles[domFlag && 'show'],
          styles[isLogin && 'gf'],
          styles[prizeType === 'REGISTER' && 'ru'],
          styles['home-prize-container']
        )}
        onClick={handleClick}
        style={
          props.prizeGuideBigBg && isBigScreen ? { backgroundImage: `url(${props.prizeGuideBigBg})` } : {}
        }
      >
        {/* // 待处理 */}
        {prizeType === 'REGISTER' ? (
          <div className={'home-prize-box'}>
            <div className={'right-container'}>
              <img className={'register-icon'} src={`/static/images/home/number/new_${language}.png`} />
              <div className={'icon-box'}>
                {bonusArr && bonusArr.length > 0 && (
                  <>
                    <img className={'top'} src={'/static/images/home/number/symbol.png'} />
                    {bonusArr.map((item, index) => (
                      <img
                        key={index}
                        className={'num'}
                        src={`/static/images/home/number/number_${item}.png`}
                      />
                    ))}
                  </>
                )}
                {freeSpinArr && freeSpinArr.length > 0 && (
                  <>
                    <img className={'num'} src={`/static/images/home/number/free_${language}.png`} />
                    {freeSpinArr.map((item, index) => (
                      <img
                        key={index}
                        className={'num'}
                        src={`/static/images/home/number/number_${item}.png`}
                      />
                    ))}
                    <img className={'bottom'} src={`/static/images/home/number/time_${language}.png`} />
                  </>
                )}
              </div>
              <div className={'sign-btn'} onClick={goRegister}>
                {prizeType === 'REGISTER' ? t('home.deposit.register') : t('home.deposit.login')}
              </div>
            </div>
          </div>
        ) : (
          <div className={'home-prize-box ph'}>
            {isBigScreen ? (
              <>
                <div className={'left-box'}>
                  {bonusArr.length > 0 && (
                    <div className={'tips-icon'}>
                      <img className={'add'} src={'/static/images/home/number/add.png'} />
                      {bonusArr.map((item, index) => (
                        <img
                          key={index}
                          className={'number'}
                          src={`/static/images/home/number/number_${item}.png`}
                        />
                      ))}

                      <img className={'percent'} src={`/static/images/home/number/percent_${language}.png`} />
                      <img
                        className={'txt'}
                        // 待处理
                        src={`/static/images/home/number/${prizeType}_${language}.png`}
                      />
                    </div>
                  )}

                  {endTime && (
                    <div className={'bi-box'}>
                      <CountDown terminalTime="endTime" />
                      <p className={'prize-txt'} dangerouslySetInnerHTML={{ __html: additionalTask }} />
                    </div>
                  )}
                  {designationTasks.length > 0 && (
                    <div className={'prize-tips'}>
                      <div className={'top-icon'}>{t('home.deposit.first')}</div>
                      <ul className={'txt-list'}>
                        {designationTasks.map((item) => (
                          <li key={item.type}>{formatTask(item)}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
                {props.prizeGuideSmallBg && <div className={'spoke'} />}
                <div className={'right-box'}>
                  {/* // 待处理 
                  // <HomeDeposit finish={() => setDomFlag(true)} />  */}
                </div>
              </>
            ) : (
              <>
                {isLogin && (
                  <p className={'title'}>{t('home.deposit.welcome', { name: userInfo.loginName })}</p>
                )}

                {bonusArr.length > 0 && (
                  <div className={cn(!isLogin && 'pl', 'tips-icon')}>
                    <img className={'add'} src={'/static/images/home/number/add.png'} />
                    {bonusArr.map((item, index) => (
                      <img
                        key={index}
                        className={'number'}
                        src={`/static/images/home/number/number_${item}.png`}
                      />
                    ))}
                    <img className={'percent'} src={`/static/images/home/number/percent_${language}.png`} />
                    {/* 待处理 */}
                    <img className={'txt'} src={`/static/images/home/number/${prizeType}_${language}.png`} />
                  </div>
                )}
                {!isBigScreen && (
                  <div
                    className={cn(designationTasks.length === 0 && 'fh', !showFlag && 'close', 'operate')}
                    style={{ transform: `translateY(-${top}px)` }}
                    onClick={(event) => {
                      event.stopPropagation()
                      setShowFlag(!showFlag)
                    }}
                  >
                    <span className={'txt'}>
                      {showFlag ? t('common.homeIcon.close') : t('common.homeIcon.open')}
                    </span>
                    {!showFlag ? <LeftOutlined className={'icon'} /> : <RightOutlined className={'icon'} />}
                  </div>
                )}
                {props.prizeGuideSmallBg && <img className={'spoke'} src={props.prizeGuideSmallBg} />}
              </>
            )}
          </div>
        )}
      </div>

      {props.banner.length > 0 && <Banner homeBanner={props.banner} />}
    </>
  )
}

export default memo(HomePrizeGuideMobile)
