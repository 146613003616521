'use client'
import { useState } from 'react'
import { useUserStore } from '@/stores/user'
import { useAuthStore } from '@/stores/authControl'
import { openGame } from '@/components/game/startGame'
// import { useAppStore } from '@/stores/app'
import { useRouter } from 'next/navigation'

// --- 待处理
export function jumpURL(link: string, type = '') {
  // export const jumpURL = (link: string, type = '') => {
  // const changeLanguage = useAppStore((state) => state.changeLanguage)  // 已由多语言插件进行切换
  const [url, setURL] = useState(link)
  const [status, setStatus] = useState(type)

  const isLogin = useUserStore((state) => state.isLogin)
  const openLogin = useAuthStore((state) => state.openLogin)
  const router = useRouter()

  if (!url) return
  if (url === '/language') {
    router.push('/')
    // changeLanguage('ja-JP')
    return
  }
  if (url === '/static/responsibleGaming') {
    router.push('/responsible')
    return
  }
  if (url.indexOf('/islotentrance') > -1) {
    if (!isLogin) {
      openLogin()
    } else {
      openGame(0, {
        platformCode: 'ISLOT',
        gameId: 'islot_lobby'
      })
    }
    return
  }

  /* 去空格 */
  const _url = url.replace(/(^\s*)/g, '')
  if (_url.startsWith('http') || status === 'new') {
    window.open(_url)
    return
  } else {
    router.push(_url)
  }
}
