import { useState, useEffect, FC } from 'react'
import { cn, onHandleAlt, preloadImg } from '@/lib/utils'
import { useTranslation } from '@/lib/locales/client'
import { useAppStore } from '@/stores/app'
import { Autoplay, Pagination, Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { webpHandle } from '@/hooks/aboutWebp'
import { jumpURL } from '@/lib/jumpURL'
import { px2Scalepx } from '@/hooks/useRem'
import styles from '../_common_css/homeIcons.module.scss'

type IProps = {
  icons: any[]
}

const HomeIcons: FC<IProps> = (props) => {
  const { t } = useTranslation()
  const isBigScreen = useAppStore((state) => state.isBigScreen)
  const [swiperKey, setSwiperKey] = useState('home-icon-swiper')
  const [isShowHomeIcons, setIsShowHomeIcons] = useState(true)
  const [isActive, setIsActive] = useState(false)
  const [onTransition, setOnTransition] = useState(false)
  const [isShowOtherIcons, setIsShowOtherIcons] = useState(false)
  const [proloadImgList, setProloadImgList] = useState([])
  const [loop, setLoop] = useState(true)
  const [swiperHeight, setSwiperHeight] = useState('auto')

  // --- 待观察
  const getSwiperHeight = () => {
    if (isBigScreen) {
      if (proloadImgList?.length > 0) {
        let totalHeight = 0
        const space = px2Scalepx(16)
        proloadImgList.forEach((item: any) => {
          const imgHeight = (item.height * px2Scalepx(115)) / item.width
          totalHeight += imgHeight
        })
        totalHeight += space * (proloadImgList.length - 1)
        const maxHeight = Math.max(
          ...proloadImgList.map((item: any) => {
            return (item.height * px2Scalepx(115)) / item.width
          })
        )
        return isActive ? totalHeight + 'px' : maxHeight + 'px'
      } else {
        return 'auto'
      }
    } else {
      return 'auto'
    }
  }

  const updateProloadImagList = () => {
    return Promise.all(
      props.icons.map((icon: any) => {
        const imgUrl = webpHandle(icon.mediaLibrary[0]?.fullWebpFilePath, icon.mediaLibrary[0]?.fullFilePath)
        return preloadImg(imgUrl)
      })
    ).then((imgs: any) => {
      setProloadImgList(imgs)
      setSwiperHeight(getSwiperHeight())
    })
  }

  useEffect(() => {
    updateProloadImagList()
  }, [props.icons])

  let iconsSwiper: any = null
  let isClickMoreAnimation = false
  const getOtherIcons = (icon: any) => {
    return props.icons.filter((item: any) => item.templateId !== icon?.templateId)
  }

  const clickMore = () => {
    setIsActive(!isActive)
    setOnTransition(true)
    isClickMoreAnimation = true
    setSwiperHeight(getSwiperHeight())
    if (isBigScreen && isActive) {
      iconsSwiper.disable()
    } else {
      iconsSwiper.enable()
    }
  }

  // --- 待处理 过渡后执行事件，有原生事件支持，后面接入
  // const onTransitionStart = () => {
  //   if (isActive) {
  //     if (!isBigScreen) {
  //       setLoop(false)
  //       setSwiperKey('home-icon-swiper' + Math.random())
  //     }
  //   }

  //   if (isBigScreen && isActive) {
  //     setIsShowOtherIcons(true)
  //   }
  // }

  // const onTransitionEnd = () => {
  //   setOnTransition(false)
  //   if (isClickMoreAnimation) {
  //     if (!isBigScreen) {
  //       setLoop(!isActive)
  //       setSwiperKey('home-icon-swiper' + Math.random())
  //     }

  //     if (isBigScreen && !isActive) {
  //       setIsShowOtherIcons(false)
  //     }
  //   }
  //   isClickMoreAnimation = false
  // }

  const onSwiperInit = (swiper: any) => {
    iconsSwiper = swiper
  }

  return (
    <>
      {props.icons.length > 0 && isShowHomeIcons && (
        <div
          className={cn(
            styles['home-icons-wrap'],
            styles[isBigScreen ? 'pc' : 'h5'],
            styles[isActive && 'active'],
            styles[onTransition && 'on-transition']
          )}
          // 待处理
          // onTransitionStart={onTransitionStart}
          // onTransitionEnd={onTransitionEnd}
        >
          <p
            className={cn(!isBigScreen && 'mobile', 'btn_close')}
            onClick={() => setIsShowHomeIcons(false)}
          ></p>

          <Swiper
            key={swiperKey + isBigScreen ? 'pc' : 'h5'}
            className={'swiper'}
            onInit={onSwiperInit}
            modules={[Autoplay, Pagination, Navigation]}
            grabCursor
            autoHeight={false}
            centeredSlides={false}
            slidesPerView={'auto'}
            spaceBetween={px2Scalepx(isBigScreen ? 16 : 8)}
            loop={loop}
            pagination={{
              el: '.home-icons-wrap-swiper-pagination .pagination'
            }}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false
            }}
          >
            {props.icons.map((item, index) => (
              <SwiperSlide key={index} style={{ height: swiperHeight }}>
                {item.mediaLibrary.length > 0 && (
                  <img
                    className={'home-icon-img'}
                    src={webpHandle(item.mediaLibrary[0].fullWebpFilePath, item.mediaLibrary[0].fullFilePath)}
                    onClick={() => jumpURL(item.templateAction)}
                    alt={onHandleAlt(item)}
                  />
                )}

                {isShowOtherIcons && getOtherIcons(item) && (
                  <div className={'other-img'}>
                    {getOtherIcons(item).map((otherImg) => (
                      <img
                        className={'home-icon-img'}
                        key="otherImg.templateId"
                        src={webpHandle(
                          otherImg.mediaLibrary[0].fullWebpFilePath,
                          otherImg.mediaLibrary[0].fullFilePath
                        )}
                        alt={onHandleAlt(item)}
                        onClick={() => jumpURL(otherImg.templateAction)}
                      />
                    ))}
                  </div>
                )}
              </SwiperSlide>
            ))}
          </Swiper>
          <div className={cn(isBigScreen ? 'pc' : 'h5', 'home-icons-wrap-swiper-pagination')}>
            <div className={'pagination'}></div>

            <span onClick={clickMore} className={'text'}>
              {isActive ? t('common.homeIcon.close') : t('common.homeIcon.more')}
            </span>
          </div>
        </div>
      )}
    </>
  )
}

export default HomeIcons
