import { useState, useRef, useEffect, FC } from 'react'
import AnimateCard from './animateCard'
import { Flex } from 'antd'
import dayjs from 'dayjs'

type IProps = {
  terminalTime: string
}

const CountDown: FC<IProps> = (props) => {
  const setIntVal = useRef(null)
  const [hours, setHours] = useState(['0', '0'])
  const [minites, setMinites] = useState(['0', '0'])
  const [seconds, setSeconds] = useState(['0', '0'])

  useEffect(() => {
    updateClock()
    setIntVal.current = setInterval(() => {
      updateClock()
    }, 1000)

    return () => {
      clearInterval(setIntVal.current)
    }
  }, [])

  function updateClock() {
    let now = new Date().getTime()
    let stopTime = 0

    // 错误入参 处理逻辑
    try {
      stopTime = dayjs(props.terminalTime).valueOf()
    } catch (err) {
      console.error(err)
      return false
    }

    // 终止逻辑
    const remainingTime = stopTime - now
    if (remainingTime < 1000) {
      clearInterval(setIntVal.current)
      setIntVal.current = null
      // 计时器 清零
      // hours.value = minites.value = seconds.value = ["0", "0"]
      setHours(['0', '0'])
      setMinites(['0', '0'])
      setSeconds(['0', '0'])
      return false
    }

    // 计算 日、时、分、秒
    const totalSeconds = Math.floor(remainingTime / 1000)
    const secondsLeft = totalSeconds % 3600
    const h = Math.floor(totalSeconds / 3600)
    const m = Math.floor(secondsLeft / 60)
    const s = secondsLeft % 60

    // 更新 data
    setHours(toStringAndUnshiftZero(h))
    setMinites(toStringAndUnshiftZero(m))
    setSeconds(toStringAndUnshiftZero(s))
  }

  function toStringAndUnshiftZero(num) {
    const val = num.toString().split('')
    if (num < 10) {
      val.unshift('0')
    }
    return val
  }

  return (
    <>
      {/* 有响应式还没测 */}
      <Flex align="center" justify="center" className="mobile: w-full justify-start">
        <AnimateCard value={hours} />
        <span className="mx-[6px] my-0 text-[20px] text-white/[80]">:</span>
        <AnimateCard value={minites} />
        <span className="symbol">:</span>
        <AnimateCard value={seconds} />
      </Flex>
    </>
  )
}

export default CountDown
