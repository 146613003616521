export const webpHandle = (webpImg: any, defaultImg?: any) => {
  // webp 图片处理
  let canUseWebP = false
  if (
    global?.window &&
    window.document.createElement('canvas').toDataURL('image/webp', 0.5).indexOf('data:image/webp') === 0
  ) {
    canUseWebP = true
  }

  if (!defaultImg) {
    return webpImg
  }
  let ret = ''
  if (canUseWebP) {
    ret = webpImg || defaultImg
  } else {
    ret = defaultImg
  }
  return ret
}
